exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-articles-post-js": () => import("./../../../src/templates/articles-post.js" /* webpackChunkName: "component---src-templates-articles-post-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-prices-js": () => import("./../../../src/templates/prices.js" /* webpackChunkName: "component---src-templates-prices-js" */),
  "component---src-templates-register-js": () => import("./../../../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-templates-service-child-js": () => import("./../../../src/templates/service-child.js" /* webpackChunkName: "component---src-templates-service-child-js" */),
  "component---src-templates-service-parent-js": () => import("./../../../src/templates/service-parent.js" /* webpackChunkName: "component---src-templates-service-parent-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

